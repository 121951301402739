<template>
  <div>
    <Back title="转赠列表" />
    <van-tabs
      v-model="active"
      background="#121212"
      color="#fff"
      title-inactive-color="#8E8E95"
      title-active-color="#fff"
    >
      <van-tab title="由我转赠">
        <van-list
          :class="$style.list"
          v-model="give.loading"
          :finished="give.finished"
          :error.sync="give.error"
          error-text="请求失败，点击重新加载"
          finished-text="没有更多了"
          @load="onLoadGive"
        >
          <GiveCard
            @refresh="handleRefresh"
            :class="$style.listItem"
            v-for="item in give.list"
            :key="item.id"
            :info="item"
          />
        </van-list>
      </van-tab>
      <van-tab title="转赠给我">
        <van-list
          :class="$style.list"
          v-model="accept.loading"
          :finished="accept.finished"
          :error.sync="accept.error"
          error-text="请求失败，点击重新加载"
          finished-text="没有更多了"
          @load="onLoadAccpet"
        >
          <GiveCard
            @refresh="handleRefresh"
            :class="$style.listItem"
            v-for="item in accept.list"
            :key="item.id"
            :info="item"
            isAccept
          />
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Back from "@/components/Back";
import GiveCard from "@/components/GiveCard";
import { fetchGiveList } from "@/api";
export default {
  components: {
    Back,
    GiveCard,
  },
  data() {
    return {
      active: Number(this.$route.query.tab) || 0, // 0由我转赠，1转赠给我
      give: {
        list: [],
        loading: false,
        finished: false,
        error: false,
        pageSize: 15,
        page: 1,
        total: 0,
      },
      accept: {
        list: [],
        loading: false,
        finished: false,
        error: false,
        pageSize: 15,
        page: 1,
        total: 0,
      },
    };
  },
  methods: {
    getGiveList() {
      const data = {
        page_size: this.give.pageSize,
        page: this.give.page,
        type: 1, // 类型:1-由我转赠,2-转赠给我
      };
      fetchGiveList(data)
        .then((res) => {
          const { list, total, page } = res;
          this.give.total = total;
          this.give.list = [...this.give.list, ...list];
          this.give.loading = false;
          this.give.page = page + 1;
          if (this.give.list.length >= this.give.total) {
            this.give.finished = true;
          }
        })
        .catch(() => {
          this.give.loading = false;
          this.give.error = true;
        });
    },
    onLoadGive() {
      this.getGiveList();
    },
    getAccpetList() {
      const data = {
        page_size: this.accept.pageSize,
        page: this.accept.page,
        type: 2, // 类型:1-由我转赠,2-转赠给我
      };
      fetchGiveList(data)
        .then((res) => {
          const { list, total, page } = res;
          this.accept.total = total;
          this.accept.list = [...this.accept.list, ...list];
          this.accept.loading = false;
          this.accept.page = page + 1;
          if (this.accept.list.length >= this.accept.total) {
            this.accept.finished = true;
          }
        })
        .catch(() => {
          this.accept.loading = false;
          this.accept.error = true;
        });
    },
    onLoadAccpet() {
      this.getAccpetList();
    },
    handleRefresh(type) {
      // type 1:转赠列表，2：接受列表
      const origin = {
        list: [],
        loading: false,
        finished: false,
        error: false,
        pageSize: 15,
        page: 1,
        total: 0,
      };
      if (type === 1) {
        // 这里替换give对象未自动刷新列表，so手动get一次
        this.give = origin;
        this.getGiveList();
      }
      if (type === 2) {
        // 这里替换accept对象，会刷新列表
        this.accept = origin;
      }
    },
  },
};
</script>

<style lang="scss" module>
.list {
  padding: 13px 20px;
  .listItem {
    margin-bottom: 23px;
  }
}
</style>
