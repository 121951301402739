<template>
  <div :class="$style.wrap">
    <div :class="$style.up">
      <div :class="$style.productInfo">
        <img :class="$style.cover" :src="info.image_url" />
        <div :class="$style.owner">
          <p :class="$style.ownerNam">{{ info.name }}</p>
          <div :class="$style.info">
            <img :class="$style.avatar" :src="info.artist_headimg" />
            <span :class="$style.name">{{ info.artist_name }}</span>
          </div>
        </div>
      </div>
      <span
        :class="[
          $style.status,
          {
            [$style.pending]: Number(info.confirm_status) === 1,
            [$style.success]: Number(info.confirm_status) === 2,
            [$style.fail]: Number(info.confirm_status) === 3,
            [$style.cancel]: Number(info.confirm_status) === 4,
          },
        ]"
        >{{
          isAccept
            ? acceptStatusMap[info.confirm_status]
            : giveStatusMap[info.confirm_status]
        }}</span
      >
    </div>
    <div :class="$style.down">
      <p :class="$style.line">
        <span>{{ isAccept ? "转赠者" : "接受者" }}</span>
        <span>{{ info.transfer_name }}</span>
      </p>
      <p :class="$style.line">
        <span>{{ timeWriting }}时间</span>
        <span>{{ timeValue }}</span>
      </p>
      <p :class="$style.line">
        <span>记录单号</span>
        <span>{{ info.transfer_no }}</span>
      </p>
    </div>
    <div
      v-if="Number(info.confirm_status) === 1 && isAccept"
      :class="$style.btnWrap"
    >
      <span :class="$style.btn" @click="handleRefuse">拒绝</span>
      <span :class="[$style.btn, $style.primary]" @click="handleAccept"
        >接受</span
      >
    </div>
    <div
      v-if="Number(info.confirm_status) === 1 && !isAccept"
      :class="$style.btnWrap"
    >
      <span :class="$style.btn" @click="handleCancel">取消转赠</span>
    </div>
  </div>
</template>

<script>
import { confirmTransfer, cancelTransfer, fetchUserInfo } from "@/api";
import { setStorage } from "@/utils/util";
export default {
  // 由我转赠列表一共四个状态：对应的文案和字段
  // 1: "转赠中", 转赠时间: created_at
  // 2: "转赠成功", 接收时间: confirm_at
  // 3: "转赠失败", 转赠时间: created_at
  // 4: "已取消", 取消时间: confirm_at
  // 转赠给我列表一共四个状态：对应的文案和字段
  // 1: "待接受",  接收时间: "-"
  // 2: "接受成功", 接收时间: confirm_at
  // 3: "已拒绝", 拒绝时间: confirm_at
  // 4: "已取消", 取消时间: confirm_at
  props: {
    isAccept: {
      type: Boolean,
      default: false, //false:由我转赠，true：转赠给我
    },
    index: {
      type: Number,
      default: 0,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 1-转赠中, 2-接收,3-拒绝，4-取消
      giveStatusMap: {
        1: "转赠中",
        2: "转赠成功",
        3: "转赠失败",
        4: "已取消",
      },
      acceptStatusMap: {
        1: "待接受",
        2: "接受成功",
        3: "已拒绝",
        4: "已取消",
      },
    };
  },
  computed: {
    // 时间文案
    timeWriting() {
      const { confirm_status } = this.info;
      let map = {};
      if (this.isAccept) {
        // 转赠给我
        map = {
          1: "接收",
          2: "接收",
          3: "拒绝",
          4: "取消",
        };
      } else {
        // 由我转赠
        map = {
          1: "转赠",
          2: "接收",
          3: "转赠",
          4: "取消",
        };
      }
      return map[confirm_status];
    },
    timeValue() {
      const { confirm_status } = this.info;
      if (this.isAccept) {
        // 转赠给我
        return this.info.confirm_at || "-";
      } else {
        // 由我转赠
        const map = {
          1: "created_at",
          2: "confirm_at",
          3: "created_at",
          4: "confirm_at",
        };
        return this.info[map[confirm_status]];
      }
    },
  },
  methods: {
    handleRefuse() {
      this.$dialog
        .confirm({
          title: "",
          message: "确认拒绝他人转赠？",
        })
        .then(() => {
          const data = {
            confirm_status: 3, //2-确认,3-拒绝
            transfer_no: this.info.transfer_no,
          };
          confirmTransfer(data).then(() => {
            this.$toast("拒绝成功");
            this.handleRefrshInfo();
            this.$emit("refresh", 2);
          });
        })
        .catch(() => {});
    },
    handleAccept() {
      const data = {
        confirm_status: 2, //2-确认,3-拒绝
        transfer_no: this.info.transfer_no,
      };
      confirmTransfer(data).then(() => {
        this.$toast("接受成功");
        this.handleRefrshInfo();
        this.$emit("refresh", 2);
      });
    },
    handleRefrshInfo() {
      fetchUserInfo().then((res) => {
        setStorage("user_info", res);
      });
    },
    handleCancel() {
      this.$dialog
        .confirm({
          title: "",
          message: "确认取消本次转赠？",
        })
        .then(() => {
          const data = {
            transfer_no: this.info.transfer_no,
          };
          cancelTransfer(data).then(() => {
            this.$toast("取消成功");
            this.$emit("refresh", 1);
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  padding: 10px;
  border-radius: 14px;
  background: #212121;
  .up {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #343434;
    .productInfo {
      display: flex;
      .cover {
        width: 89px;
        height: 89px;
        border-radius: 12px;
        margin-right: 17px;
      }
      .owner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 140px;
        font-weight: 500;
        .ownerNam {
          line-height: 20px;
          @include ellipsis(2);
        }
        .info {
          display: flex;
          align-items: center;
          .avatar {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .name {
            font-size: 12px;
            color: #b9b9b9;
            @include ellipsis(1);
          }
        }
      }
    }
    .status {
      line-height: 20px;
      &.success {
        color: #3894df;
      }
      &.pending {
        color: #07c160;
      }
      &.cancel {
        color: #e6e8ea;
      }
      &.fail {
        color: #ec3d3d;
      }
    }
  }
  .down {
    padding: 2px 0 7px;
    .line {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
    }
  }
  .btnWrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 11px;
    border-top: 1px solid #343434;
    .btn {
      width: 76px;
      height: 30px;
      box-sizing: border-box;
      line-height: 28px;
      text-align: center;
      border-radius: 15px;
      border: 1px solid #ffffff;
      &.primary {
        background: #ffffff;
        border: 1px solid #ffffff;
        color: #000;
        margin-left: 10px;
      }
    }
  }
}
</style>
