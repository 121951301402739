// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index_list_3VZ08{padding:.34667rem .53333rem}.index_list_3VZ08 .index_listItem_4pdd2{margin-bottom:.61333rem}", ""]);
// Exports
exports.locals = {
	"list": "index_list_3VZ08",
	"listItem": "index_listItem_4pdd2"
};
module.exports = exports;
